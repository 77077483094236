import * as React from "react"
import useStoryblok from "../../src/lib/storyblok"
import StandardPageLayout from "../components/layout/StandardPageLayout"
import StoryblokHelmet from "../components/internal/StoryblokHelmet"


const StandardPage = ({ pageContext, location }) => {
  let story = pageContext.story
  story = useStoryblok(story, location)
  const { meta } = pageContext.story.content
  
  if(meta && !meta.title) { 
    meta.title = story.name
  }

  const mainContent = story.story ? story.story.content : story.content
  return (
    <React.Fragment>
      <StoryblokHelmet meta={meta} />
      <StandardPageLayout 
        pagePath={location.pathname}
        pagination={pageContext.pagination}
        mainContent={mainContent}
        newsStories={pageContext.newsStories} />
    </React.Fragment>
  )
}

export default StandardPage